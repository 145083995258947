:root {
    --cl-hover: #f8f9fa
}

.main-content {
    height: 100vh;
    overflow: auto;
}

.nv-grid {
    display: grid;
    width: 100%;

}

.nv-row {
    flex: 100%;
    display: flex;
}

.nv-row-p5>* {
    padding-right: 5px;
}

.nv-row-p5> :last-child {
    padding-right: unset;
}

.nv-row-m5>* {
    margin-right: 5px;
}

.nv-row-m5> :last-child {
    margin-right: unset;
}


table.loading tbody tr {
    background-image: linear-gradient(to right, transparent 50%, rgba(0, 0, 0, .05) 50%);
    background-size: 200% 100%;
    animation: loading 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

table.loading tbody tr td {
    opacity: 0.45;
    pointer-events: none;
}

@keyframes loading {
    0% {
        background-position: 0;
    }

    50% {
        background-position: -30%;
    }

    80% {
        background-position: -100%;
    }

    100% {
        background-position: -200%;
    }
}

.btn.btn-block:focus,
.btn.btn-block:active {
    outline: none;
    box-shadow: none;
}



table thead .sortable {
    cursor: pointer;
}

table thead .sortable:hover {
    background-color: var(--cl-hover);
    /* background-color: rgba(0, 0, 0, .05); */
}

table thead .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

table thead .dropup .caret,
table thead .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}

.tooltip-inner:has(img) {
    background-color: unset;
    opacity: 1;
}

.align-r {
    text-align: right;
    justify-content: flex-end;
}

.table-responsive {
    margin-bottom: 15px;
}

.table-responsive td {
    white-space: break-spaces !important;
}

.table-responsive th {
    white-space: break-spaces !important;
}

.table thead,
.table tbody,
.table tr,
.table th,
.table td {
    border-color: rgb(113 125 157 / 31%);
}

.card {
    word-wrap: normal;
}

.btn-nohover {
    pointer-events: none;
    display: inline-block;
}

.nv-sticky-card {
    background-color: #2a3042;
    position: fixed;
    bottom: 0px;
    z-index: 1;
}

.table-responsive:has(tr td .dropdown-menu.show) {
    min-height: 300px;
}

.custom-loadingIndicator {
    display: flex;
    transition: color 150ms ease 0s;
    align-self: center;
    font-size: 4px;
    line-height: 1;
    margin-right: 4px;
    text-align: center;
    vertical-align: middle;
    color: rgb(204, 204, 204);
    padding: 8px;
    box-sizing: border-box;
}

@keyframes custom-loadingIndicator-animation {
    0% {
        background-color: rgb(204, 204, 204);
    }

    50% {
        background-color: rgb(143, 140, 140);
    }

    100% {
        background-color: rgb(204, 204, 204);
    }
}

.custom-loadingIndicator-item1 {
    animation: 1s ease-in-out 0ms infinite normal none running custom-loadingIndicator-animation;
    background-color: currentcolor;
    border-radius: 1em;
    display: inline-block;
    height: 1em;
    vertical-align: top;
    width: 1em;
}

.custom-loadingIndicator-item2 {
    animation: 1s ease-in-out 160ms infinite normal none running custom-loadingIndicator-animation;
    background-color: currentcolor;
    border-radius: 1em;
    display: inline-block;
    margin-left: 1em;
    height: 1em;
    vertical-align: top;
    width: 1em;
}

.custom-loadingIndicator-item3 {
    animation: 1s ease-in-out 320ms infinite normal none running custom-loadingIndicator-animation;
    background-color: currentcolor;
    border-radius: 1em;
    display: inline-block;
    margin-left: 1em;
    height: 1em;
    vertical-align: top;
    width: 1em;
}

.page-item.active .page-link {
    z-index: unset;
}

.table> :not(caption)>*>* {
    padding: 0.15rem;
}

table .btn {
    padding: 0.15rem 0.3rem;
}

.text-bold {
    font-weight: bold;
}